export const leaderboard = {
  quiz: {
    id: 1,
    name: "React Quiz",
  },
  users: [
    
      {
        id: 1,
        fullname: "John Doe",
        score: 50,
        elapsedTime: 200,
        rank: 1,
      },
      {
        id: 2,
        fullname: "Jane Smith",
        score: 45,
        elapsedTime: 220,
        rank: 2,
      },
      {
        id: 3,
        fullname: "Alice Johnson",
        score: 42,
        elapsedTime: 210,
        rank: 3,
      },
      {
        id: 4,
        fullname: "Bob Brown",
        score: 40,
        elapsedTime: 230,
        rank: 4,
      },
      {
        id: 5,
        fullname: "Charlie Davis",
        score: 38,
        elapsedTime: 240,
        rank: 5,
      },
      {
        id: 6,
        fullname: "Diana Evans",
        score: 35,
        elapsedTime: 250,
        rank: 6,
      },
      {
        id: 7,
        fullname: "Frank Green",
        score: 33,
        elapsedTime: 260,
        rank: 7,
      },
      {
        id: 8,
        fullname: "Grace Hall",
        score: 30,
        elapsedTime: 270,
        rank: 8,
      },
      {
        id: 9,
        fullname: "Henry Lee",
        score: 28,
        elapsedTime: 280,
        rank: 9,
      },
      {
        id: 10,
        fullname: "Ivy Martinez",
        score: 25,
        elapsedTime: 290,
        rank: 10,
      },
  ],
};
