function Option({label,id,name,isSelected=false,onSelect}){

    const htmlOutput=<label className={"row py-3 my-3 "+(isSelected?"selected":"gq-card-bg")}> 

        <div className="option-control col-1 text-center">
            <input onChange={onSelect} type="radio" name={name} value={id} id={id} />
        </div>
        
        <div className="option-control col-10">
            <label htmlFor={id}>{label}</label>
        </div>
        

    </label>

    return htmlOutput;
    
}
export default Option;
