import getExploreText from "./textContent/explore.text";

import { LangContext } from "../AppContext";

import { useContext, useEffect, useState } from "react";

import Container, { Col,ContainerRow } from "./bootstrap/Container";

import { GqContainerRowWarp } from "./template/GqContainer";

import "../styles/explore.css"


import Launch from "../launcher/Launch";

import { getTopics } from "../gqDataPackage/topics";

import Topic from "./Topic";

import AppEnv from "../App.env";

function Explore() {

  const [userIsLoggedIn,setUserIsLoggedIn] = useState(false);

  const language=useContext(LangContext);

  const content=getExploreText(language.lang);

  const [topicsLoaded,setTopicsLoaded]=useState(false);

  const [topics,setTopics]=useState([]);

  const [showForYou,setShowForYou] = useState(false);

  useEffect(function(){

    (async ()=>{

      setUserIsLoggedIn(await AppEnv.isUserLogged());
        
      const topics=await getTopics();

      setTopics(topics);

      setShowForYou(topics.filter((topic)=>{return topic.section==="for_you"}).length>0);
  
    })()
  
  },[]);

  useEffect(function(){
    if(topics.length>0){
      setTopicsLoaded(true);
    }
  },[topics])

  const htmlOutput=<>

      <GqContainerRowWarp>
        
        <Launch justSleep={true} sleepTime={187}>

          <Col className="p-3 py-1">
  
              {/* <h1>{content.title}</h1> */}

              <p><strong>Welcome to <span className="gq-highlight">G-Kwiz</span> !</strong>{content.message}</p> 

                <a className="gq-a-button" href="#explore-flow">

                {content.browse}{" "}
                
                <i className="bi bi-compass-fill"></i>
              
                </a>

          </Col>

          <Col className="text-center my-2">

				  </Col>

          {/* <Col>

            <div className="text-center p-2">
              or
            </div>

            <div className="p-2">

              <input type="search" placeholder={content.searchBarPlaceholder} />

            </div>

            <div className="p-2">
            
              <button className="gq-button"><i className="bi bi-search"></i></button>

            </div>

          </Col>
         */}
        </Launch>
      
      </GqContainerRowWarp>

    <Container id="explore-flow" className="m-0 p-0" fluid={true}>
      
      <ContainerRow  className="pt-5 text-light transparent-cover" fluid={true}>

        {/* <Col className="text-center">
          <button className="gq-button">Filters <i className="bi bi-sliders2"></i></button>
        </Col> */}

        <Launch dataLoaded={topicsLoaded} >

          {(userIsLoggedIn&&showForYou)&&(<div className="col-12 text-center gq-highlight">
            <hr/>
            For You
            <hr/>
          </div>)}

          {
          topicsLoaded&&topics.map((topic)=>(topic.section==="for_you"&&<Topic key={topic.topicId} topic={topic} />))
          }

          {userIsLoggedIn&&(<div className="col-12 text-center gq-highlight">
            <hr/>
              Exploring Fascinating Topics
            <hr/>
          </div>)}

          {
          topicsLoaded&&topics.map((topic)=>(topic.section==="others"&&<Topic key={topic.topicId} topic={topic} />))
          }

        </Launch>

      </ContainerRow>

    </Container>


  </>

  return htmlOutput;

}

export default Explore;
