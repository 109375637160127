import React, { useState } from 'react'
import Question from './Question';

export const Board = ({questions=[],setQuizEnd,setProgress,setChoices,choices}) => {

	const [activeQUestion,setActiveQuestion]=useState(0);

    function handleNext(selectedOption,nextQuestionIndex){
  
        setActiveQuestion(nextQuestionIndex);
        
        const copyChoices=[...choices];
        
        copyChoices.push(selectedOption);
        
        setChoices(copyChoices);
        
        setProgress((((parseInt(activeQUestion)+1)*100)/questions.length));
        
        if(nextQuestionIndex===parseInt(questions.length)){

            setQuizEnd(true);

        }
        
    }
    

  return questions.map((question,index) => (<Question explanation={question.explanation} index={index} onNext={handleNext} active={index===activeQUestion}  label={question.label} key={question.questionId} options={question.options} id={question.questionId} />))
}


