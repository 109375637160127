import { Link } from 'react-router-dom';

const NoQuizMessage = () => {
  return (
    <div className='text-center'>
      <p>No quizzes available for this profile.</p>
      <Link to="/explore" style={{  padding: '10px 20px',  }}>
        Take a Quiz
      </Link>
    </div>
  );
};

export default NoQuizMessage;
