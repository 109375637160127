export default function getModulesText(lang){
    const text={
        en:{
            modules:"Modules",
            time:"minutes",
            startQuizLinkLabel:"Start Quiz",
            leaderboardLinkLabel:"Leaderboard"
        }
    }
    return text[lang];
}