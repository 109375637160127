// Modal.js
import React from 'react';
import '../../styles/modal.css'; // Import a CSS file for styling

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="gq-modal-overlay">
      <div className="gq-modal-content">
        <button className="gq-modal-close" onClick={onClose}>
          &times;
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
