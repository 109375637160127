import { Link, useNavigate, useParams } from "react-router-dom";

import Answer from "./Answer";

import getResultText from "./textContent/results.text";

import { useContext, useState } from "react";

import { LangContext } from "../AppContext";

import Container, { Col, ContainerRow } from "./bootstrap/Container";

import GqContainerRow from "./template/GqContainer";

import { formatTime } from "./Timer";

import Modal from "./customBlocks/Modal";

import ShareComponent from "./ShareComponent";

import AppEnv from "../App.env";
 
function Results({infos}) {

  const [shareModalOpen,setShareModalOpen] = useState(false);

  const params=useParams();

  const navigate=useNavigate();

  const duration=formatTime(infos.duration);

  const choices=[...infos.choices];

  const questions=infos.quiz.questions;

  const score=(parseInt(choices.filter((choice)=>choice.isCorrect).length)*100)/questions.length;

  const content=getResultText(useContext(LangContext).lang);

  const htmlOutput= <GqContainerRow>
      
      <Col sm="4" className="p-3 py-0 transparent-cover-2">

          <div className="row">

            <div className="col-6">

              <Link to={"/explore"}><i className="bi bi-house-fill"></i>{" "}{content.goBack}</Link>

            </div>

            <div className="col-6 text-end">

              <Link onClick={()=>{setShareModalOpen(true)}} href="#valid">Share <i className="bi bi-share-fill"></i></Link>

            </div>

          </div>
      
      </Col>

      <Col className="p-5 transparent-cover-2 text-center">
      
          <h1 >{content.title}{" "}<i className="bi bi-file-bar-graph-fill"></i></h1> 
          <hr/>
          <h2> {content.yourScore(score,infos.quiz.label)}</h2>

          <div className="gq-highlight"><h2>Duration : {duration} </h2></div>
          <div>
            <button className="gq-button" onClick={(e)=>{e.preventDefault();navigate("/topic/"+params.slug+"/"+params.quiz)}}>Retry <i className="bi bi-arrow-counterclockwise"></i></button>
          </div>
          <hr />
          <div>
            <button className="gq-button" onClick={()=>{setShareModalOpen(true)}}>Share <i className="bi bi-share-fill"></i></button>
          </div>

          <Modal isOpen={shareModalOpen} onClose={()=>{setShareModalOpen(false)}}>
		
    <ShareComponent links={AppEnv.shareQuiz(infos.quiz.label,"/topic/"+params.slug+"/"+params.quiz,score)} />

  </Modal>

      </Col>
      
      <Col className="p-5 transparent-cover-2">

        <ContainerRow>
          <Col>
            <h4 className="gq-highlight text-center">Correction</h4>
          </Col>
          <hr/>
        </ContainerRow>
    
        <Container>
          
          {questions.map((question)=>{return <Answer selected={choices.find((choice)=>(choice.question.questionId===question.questionId))} question={question} key={question.questionId} />})}
        
        </Container>

      </Col>
  

    </GqContainerRow>

  return htmlOutput;

}

export default Results;