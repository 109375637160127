import React from "react";
import Loader from "./template/Loader";

export default function ProcessingRequest() {
	return (
		<div className="container">
			<Loader />
			<hr />
			<div className="text-center">Request Processing</div>
		</div>
	);
}
