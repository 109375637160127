import React, { useState } from "react";
import InputBox from "./customBlocks/form/InputBox";
import { Col, ContainerRow } from "./bootstrap/Container";
import { requestPasswordReset } from "../gqDataPackage/user";
import Loader from "./template/Loader";
import { useNavigate } from "react-router-dom";

function RequestPasswordReset() {

	const [email, setEmail] = useState("");

	const [requestStart, setRequestStart] = useState(false);

	const [showError, setShowError] = useState(false);

	const [emailSent, setEmailSent] = useState(false);

	const navigate = useNavigate();

	const handleSubmit = async (e) => {

		e.preventDefault();

		const _email = email.toLowerCase();

		setRequestStart(true);

		const response = await requestPasswordReset(_email);

		if (response.status) {

			setEmailSent(true);

		} else {

			setRequestStart(false);

			setShowError(true);
		
		}
	
	};

	const loader = (
	
		<ContainerRow className="p-4" fluid={true}>
		
			<Loader /> <hr />
		
			<div className="text-center">Requesting password reset</div>
		
		</ContainerRow>
	);

	const successMessage = (
		
		<ContainerRow className="p-4" fluid={true}>
		
			<Col md="6" lg="6" className="p-4">
		
				<h1>We've sent you an E-mail!!</h1>
		
				<p>Your request has been accepted. We've sent you an email with a link to recover your password.</p>
		
				<button className="gq-button" onClick={()=>{navigate("/login")}}>Ok</button>
		
			</Col>
		
		</ContainerRow>
	
	);

	const formOutput = (
	
		<>
		
			<ContainerRow className="p-4" fluid={true}>
		
				<form onSubmit={handleSubmit}>
		
					<Col md="6" lg="6" className="p-4">
		
						<h1>Request Password Reset</h1>
		
						<p>Please enter your email address to receive a password reset link.</p>
		
						<hr />
		
						<InputBox
							autofocus={true}
							icon="bi bi-person-fill"
							label="E-mail"
							inputName="email"
							inputType="email"
							placeholder="Type your E-mail here"
							classname="gq-input"
							eventHandlers={[
								function handleChange(e) {
									setEmail(e.target.value);
									setShowError(false);
								},
							]}
						/>
		
						{showError && <p className="text-danger">We were unable to locate your email '{email}' in our database!</p>}
					</Col>
					<Col className="text-end" md="6" lg="6">
						<button type="submit" className="gq-button">
							Submit
						</button>
					</Col>
				</form>
			</ContainerRow>
		</>
	);

	if (requestStart) {
		return (
			<>
				{!emailSent && loader}
				{emailSent && successMessage}
			</>
		);
	} else {
		return <>{formOutput};</>;
	}
}

export default RequestPasswordReset;
