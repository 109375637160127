import { useContext, useEffect, useState } from "react";

import { isValidEmail } from "../utils";

import { mailFound } from "../gqDataPackage/user";

import InputBox from "./customBlocks/form/InputBox";

import { useNavigate } from "react-router-dom";

import getSignIntext from "./textContent/signIn.text";

import { Col } from "./bootstrap/Container";

import GqContainerRow from "./template/GqContainer";

import Login2 from "./Login2";

import Launch from "../launcher/Launch";

import { UserContext } from "../AppContext";

import AppEnv from "../App.env";

import ProcessingRequest from "./ProcessingRequest";

function Login() {

	const [requestSent,setrequestSent] = useState(false);

	const content = getSignIntext("en");

	// eslint-disable-next-line no-unused-vars
	const {user,setUser} = useContext(UserContext);

	const [stepIndex, setStepIndex] = useState(0);

	const [mailValidationMessage, setMailValidationMessage] = useState(null);

	const [showAlert, setShowAlert] = useState(false);

	const navigate = useNavigate();

	useEffect(function(){
		AppEnv.logOut();
		setUser({});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	async function handleSubmit(e) {

		const typedEmail = e.target["email"].value;

		e.preventDefault();

		setrequestSent(true);

		if (await mailFound(typedEmail)) {

			localStorage.setItem("login_email", typedEmail);

			setStepIndex(1);

		} else {

			setShowAlert(true);

			setMailValidationMessage(content.emailNotFound);
		}

		setrequestSent(false);

	}

	function handleChange(e) {

		setMailValidationMessage(isValidEmail(e.target.value) ? "" : content.enterValidEmail);

		setShowAlert(!isValidEmail(e.target.value));

	}

	let htmlOutput = (

		<GqContainerRow id="sign-in-container">

			<Col md="6" lg="6" className="gq-card-bg px-5 py-5">

				<h1>
					{content.title} <i className="bi bi-person-fill"></i>
				</h1>

				<p>{content.pageMessageParagraph}</p>

				<hr />

				<form onSubmit={(e) => handleSubmit(e)}>
					<InputBox
						classname="gq-input"
						label={content.emailInputLabel}
						inputName="email"
						inputType="email"
						placeholder={content.emailInputPlaceholder}
						eventHandlers={[handleChange]}
						autofocus={true}
					/>

					<div className="p-3 text-center">
						
						<button className="gq-button" type="submit">

							{content.loginButtonLabel}

						</button>

					</div>

				</form>

				{showAlert && (
					<div className="text-danger text-center">
						<small>{mailValidationMessage}</small>
					</div>
				)}

				<hr />

				<p className="text-center pt-4">
					<button onClick={()=>{navigate("/signup")}} className="gq-button">{content.newToWebsite}</button>
				</p>

			</Col>

		</GqContainerRow>
	);

	if(requestSent){

		return <ProcessingRequest />;

	}

	return stepIndex === 1 ? ( <Launch justSleep={true}><Login2 setStepIndex={setStepIndex} /></Launch>) : (htmlOutput);
	
}

export default Login;
