import { useNavigate } from "react-router-dom";

const ProfileUpdateFailureMessage = () => {
  const navigate = useNavigate();
	const handleButtonClick = () => {
    navigate("/settings");
  };

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
        <h1>Update Status</h1>
					<p>Failed to update the profile. Please try again.</p>
					<button className="gq-button" onClick={handleButtonClick}>Ok</button>
				</div>
			</div>
		</div>
	);
};

export default ProfileUpdateFailureMessage;
