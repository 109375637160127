import { apiRequest } from "../utils";
import { sessionPostData, updateSession } from "./session";
import { syncUser } from "./user";
import { baseUrl } from "./config";

export async function getTopics() {
	
	const userSynced = await syncUser();
	
	let response = [];
	
	if(userSynced){
	
		const body = sessionPostData();
	
		response = await apiRequest(baseUrl + "?load=explore",body);
	
		if(response[0].execute){
	
			updateSession(response[0].session.account);
	
			return response[0].topics;
	
		}
	
	}
	
	response = await apiRequest(baseUrl + "?load=explore");
	
	return response[0].topics;
}

export async function getTopic(topicId) {
	const response = await apiRequest(baseUrl + "?load=quizzes&topicId=" + topicId);
	return response[0];
}

export async function getQuiz(quizId, questions = false) {
	const sessionData = sessionPostData();
	const url = baseUrl + "?load=" + (questions ? "questions" : "quiz") + "&quizId=" + quizId;
	const response = await apiRequest(url, questions ? sessionData : null);
	if (questions) {
		if (response[0].execute) updateSession(response[0].session.account);
	}
	return response[0];
}

export async function requestStartQuiz(quizId, startTime) {
	const url = baseUrl + "?action=startQuiz&quizId=" + quizId + "&startTime=" + startTime;
	const sessionData = sessionPostData();
	const response = await apiRequest(url, sessionData);
	if (response[0].execute) updateSession(response[0].session.account);
	return response[0];
}

export async function requestCategories(connected = false) {
	const url = baseUrl + "?load=categories";
	if (connected) {
		const sessionData = sessionPostData();
		const response = await apiRequest(url, sessionData);
		if (response[0].execute) {
			updateSession(response[0].session.account)
			return response[0];
		}
	}
	return (await apiRequest(url))[0];
}

export async function sendUserInterests(interests) {
	const body = sessionPostData();

	body.append("interests", JSON.stringify(interests));

	const url = baseUrl + "?action=setInterests";

	const request = (await apiRequest(url, body))[0];

	if (request.execute) {
		updateSession(request.session.account);
	}

	return request;
}

export async function requestStopQuiz(playInfo) {
	const url = baseUrl + "?action=stopQuiz";

	const sessionData = sessionPostData();

	const datasToSend = { ...playInfo };

	const stringPlayInfo = JSON.stringify(datasToSend, (key, value) => {
		if (key === "options") {
			return "circular";
		}

		return value;
	});

	sessionData.append("playInfo", stringPlayInfo);

	const response = await apiRequest(url, sessionData);

	if (response[0].execute) {
		updateSession(response[0].session.account);
	}
}
