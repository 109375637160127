import { useNavigate } from "react-router-dom";

import { getTopicLinkBase } from "../utils";

import getExploreText from "./textContent/explore.text";

import { useContext } from "react";

import { LangContext } from "../AppContext";

import { Col, ContainerRow } from "./bootstrap/Container";

import Launch from "../launcher/Launch";

function Topic({ topic }) {

  const content=getExploreText(useContext(LangContext).lang);

  const navigate=useNavigate();

  const htmlOutput=<Col className="py-3 ">

      <ContainerRow fluid={true}>

        <div className="gq-card-bg p-5 pb-2 gq-card-picture-warper col-12 col-md-3">
          
          <Launch image={topic.coverPicture}>
          
            <img className="gq-img-fluid" src={topic.coverPicture} alt={topic.name} />
          
          </Launch>

        </div>

        <div className="gq-card-bg p-5 pt-2 col-12 col-md-9">

            <div className="gq-card-content">

              <b className="gq-highlight">{topic.name}(<small>{topic.quizAmount}{" "}{content.quiz}{" "}</small>)</b>

              <p>{topic.about}</p>

            </div>

            <div className="gq-card-controls">

              <div className="text-center"> 

                <button onClick={(e)=>{e.preventDefault();navigate(getTopicLinkBase(topic.topicId,topic.name))}} className="gq-button">{content.start}{" "}<i className="bi bi-eye-fill"></i></button>
                
              </div>
              

            </div>

        </div>

      </ContainerRow>
          
    </Col>

  return htmlOutput;

}

export default Topic;

