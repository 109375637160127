import React, { useEffect, useState } from "react";
import Loader from "../components/template/Loader";
import loadImage, { loadImages } from "./loadImage";

function Launch({ image=null,images=[],dataLoaded=true,justSleep=false,sleepTime=156, children }) {

	const [imageIsLoaded, setimageIsLoaded] = useState(false);

	const [imagesLoaded, setimagesLoaded] = useState(false);

	const [sleepEnded,setSleepEnded] = useState(false);

	useEffect(()=>{

		if(justSleep){

			setTimeout(()=>{

				setSleepEnded(true);

			},sleepTime)

		}else{

			setSleepEnded(true);

		}

	},[justSleep,sleepTime]);

	useEffect(() => {

		if(!(image===null)){

			loadImage(image,setimageIsLoaded);

		}else{

			setimageIsLoaded(true);

		}

	}, [image]);

	

	useEffect(()=>{

		if(images.length>0){

			loadImages(images,setimagesLoaded);

		}else{

			setimagesLoaded(true);

		}

	},[images])

	return <>{(imageIsLoaded && imagesLoaded && dataLoaded && sleepEnded) ? children : <Loader />}</>;
}

export default Launch;
