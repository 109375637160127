import Option from "./Option";

import Container, { Col, Row } from "./bootstrap/Container";

import { useState } from "react";

import "../styles/question.css";

function Question({active,label,options,id,onNext,index,explanation}) {

      const [selectedOption,setSelectedOption]=useState(-1);

      const [buttonDisabled,setbuttonDisabled]=useState(true);

      function handleSubmit(e){

          e.preventDefault();

          let sel=options.find((option)=>(parseInt(option.optionId)===parseInt(selectedOption)));

          sel.question={
            questionId:id,
            label:label,
            options:options,
            explanation:explanation
          }

          onNext(sel,parseInt(index)+1);

          setSelectedOption(-1);        

      }

      function handleSelect(id){

        setSelectedOption(parseInt(id));

        setbuttonDisabled(false);

      }

      const htmlOutput = <Container fluid={true} className={(active?"q-active":"q-wait")+" gq-card-bg p-5"}>
        <Row>
          
          <Col>
          
            <h4>{label}</h4>
          
          </Col>
        
        </Row>

        <form id={"question-"+id} onSubmit={(e)=>handleSubmit(e)}>

          {options.map((option)=>(<Option isSelected={selectedOption===parseInt(option.optionId)} onSelect={()=>{handleSelect(option.optionId)}} key={option.optionId} name={"questions"} id={"question-"+id+"-option-"+option.optionId} label={option.label} />))}
        
          <div><button disabled={buttonDisabled} className="gq-button" type="submit">Next</button></div>

        </form>

      </Container>

  return htmlOutput;
  
}
export default Question;
