import { useNavigate, useParams } from "react-router-dom";

import Container, { Col } from "./bootstrap/Container";

import GqContainerRow, { GqContainerRowWarp } from "./template/GqContainer";

import { useEffect, useState } from "react";

import { getIdFromSLug, shuffleArray } from "../utils";

import { getQuiz, requestStartQuiz, requestStopQuiz } from "../gqDataPackage/topics";

import Launch from "../launcher/Launch";

import Timer from "./Timer";

import Results from "./Results";

import { Board } from "./Board";

function Play() {

	const params=useParams();

	const navigate=useNavigate();

//   const topicId=getIdFromSLug(params.slug);  

    const quizId=getIdFromSLug(params.quiz);

	const [quiz,setQuiz]=useState({});

    const [quizLoaded,setQuizLoaded]=useState(false);
	
	const [progress,setProgress]=useState(0);

	const [quizStart,setQuizStart]=useState(false);

	const [quizEnd,setQuizEnd]=useState(false);

	const [playInfo,setPlayInfo]=useState({});

	const [choices,setChoices]=useState([]);

	useEffect(function(){
		
		(async ()=>{
			
			let quizRequest=await getQuiz(quizId,true);

			if(quizRequest.execute){

				const quiz=quizRequest.quizInfo;

				const currentQuiz={...quiz.quiz,questions:quiz.questions};

				const quizStartAt= Date.now();
			
				const backend= await requestStartQuiz(currentQuiz.quizId,quizStartAt);

				const playId=backend.playId;
    	        
				setQuiz(currentQuiz);

				quiz.questions=shuffleArray(quiz.questions);
			
				quiz.questions.map((question)=>{question.options=shuffleArray(question.options);return question;}); 
           
				setQuizLoaded(true);
			
				setQuizStart(true);
				
			
				setPlayInfo(play => ({
					...play,
					playId:playId,
					startTime: quizStartAt,
					quiz: currentQuiz,
					choices: []
				}));


		
			}else{
				navigate("/login");
			}

        })()
		
		// eslint-disable-next-line
    },[]);

	useEffect(() => {
		// Create a new object to update state
		setPlayInfo(prevPlayInfo => ({
			...prevPlayInfo,
			choices: choices 
		}));
	}, [choices]);

	useEffect(() => {
		// Ensure immutability by creating a new object
		if (quizEnd) {
			const endTime=Date.now();
			setPlayInfo(prevPlayInfo => ({
				...prevPlayInfo,
				endTime: endTime,
				duration: endTime - prevPlayInfo.startTime
			}));
		}
	}, [quizEnd]);

	useEffect(function(){
		if(quizEnd){
			requestStopQuiz(playInfo);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[playInfo]);
	


	const htmlOutput = (

		<Container className="transparent-cover-2 ">

			<GqContainerRowWarp>

				<Col className="text-center">

					<div id="question-time"> {quizLoaded&&<Timer duration={parseInt(quiz.duration)} start={quizStart} setQuizEnd={setQuizEnd} />} </div>

				</Col>

				<Col>
					<div className="progress bg-dark">
				
				  		<div className="progress-bar" role="progressbar" style={{width: progress+"%"}} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">{progress}%</div>
				
					</div>
				</Col>

			</GqContainerRowWarp>
	
			<hr/>

			<GqContainerRow className="board">

					<Launch dataLoaded={quizLoaded}>

						{quizLoaded&&<Board setProgress={setProgress} questions={quiz.questions} setQuizEnd={setQuizEnd} setChoices={setChoices} choices={choices}/>}
					
					</Launch>

			
			</GqContainerRow>

		</Container>
	);
	
	return quizEnd?<Results infos={playInfo}/>:htmlOutput;
}
export default Play;
