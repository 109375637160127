import { Link, useNavigate } from "react-router-dom";

import InputBox from "./customBlocks/form/InputBox";

import { useContext, useState } from "react";

import getSignIntext from "./textContent/signIn.text";

import { Col} from "./bootstrap/Container";

import GqContainerRow from "./template/GqContainer";

import { login } from "../gqDataPackage/user";

import { saveUser, updateAccount } from "../gqDataPackage/session";
import { UserContext } from "../AppContext";
import Loader from "./template/Loader";

function Login2({setStepIndex}){

    const [requestSent,setrequestSent] = useState(false);

    const content=getSignIntext("en")

    const navigate=useNavigate();

    // eslint-disable-next-line no-unused-vars
    const {user,setUser} = useContext(UserContext);

    const [passwordErrorMessage,setPasswordErrorMessage]=useState(null);

    const email=localStorage.getItem("login_email");


    function handleChange(){

        setPasswordErrorMessage("");

    }

    async function handleSubmit(e){

        e.preventDefault();

        const user={};

        user.email=email;

        user.password=e.target["password"].value;

        setrequestSent(true);

        const loguser=await login(user);

        if(loguser.status==="login_success"){

            localStorage.removeItem("login_email");

            updateAccount(loguser.login.account);

            saveUser(loguser.login.user);

            setUser(loguser.login.user);

            navigate("/explore");

        }else{

            setPasswordErrorMessage(content.passwordErrorMessage);

        }
        
        setrequestSent(false);

    }

    if(requestSent){
		return (<div className="container">
				<Loader/>
				<hr/>
				<div className="text-center">
					Request Processing
				</div>
			</div>);
	}

    const htmlOutput=<>
    
            <GqContainerRow id="sign-in-container">

                <Col md="6" lg="6" className="gq-card-bg px-5 py-5">

                    <h2>{content.title}</h2>

                    <div>

                        <em>
                            <strong>
                                <span className="gq-highlight">{email}</span>{" "}<button className="gq-button" onClick={()=>{setStepIndex((step)=>{return step-1;})}}>Edit</button>
                            </strong>
                        </em>

                        <hr/>

                    </div>

                    <form onSubmit={(e)=>handleSubmit(e)}>
        
                        <InputBox classname="gq-input" eventHandlers={[handleChange]} placeholder={content.enterPassword} label="Password" inputName="password" inputType="password"  />
                            
                        <div className="text-center p-3">
            
                            <button type="submit" className="gq-button">

                                {content.loginButtonLabel}
                                
                            </button>
        
                        </div>
        
                        <div className="text-danger p-3">
        
                            {passwordErrorMessage}
        
                        </div>
        
                        <div className="text-center">
            
                            <small>

                                <Link to="/request-password-reset" >{content.passwordForgottenMessage}</Link>
            
                            </small>
        
                        </div>

                    </form>

                </Col>

            </GqContainerRow>

    </>

    return htmlOutput;
}
export default Login2;