import { useNavigate } from "react-router-dom";

import { useParams } from "react-router-dom";

import { getIdFromSLug } from "../utils";

import  getModuleIntroductionText  from "./textContent/modules.text";

import { useEffect, useState } from "react";

import Container, { ContainerRow, Col } from "./bootstrap/Container";

import { getQuiz } from "../gqDataPackage/topics";

import Launch from "../launcher/Launch";

function Intro(){

    const content=getModuleIntroductionText("en");

    const params=useParams();

    const quizId=getIdFromSLug(params.quiz);

    const [quiz,setQuiz]=useState({});

    const [quizLoaded,setQuizLoaded]=useState(false);

    useEffect(function(){
        (async ()=>{
            const quiz=await getQuiz(quizId)
            setQuiz(quiz);
            setQuizLoaded(true);
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    const navigate= useNavigate();

    function handleClick(e){
        
        e.preventDefault();

        navigate("/topic/"+params.slug+"/"+params.quiz+"/play");

    }
    const htmlOutput=<Container className="p-0 gq-container transparent-cover">

        <ContainerRow  className="px-5 gq-page-content">

            <Col className="gq-card-bg p-5" md="6" lg="6">

                <Launch dataLoaded={quizLoaded}>
                    <img src={quiz.coverPicture} className="gq-img-fluid" alt={quiz.label}/>
                </Launch>
                
            </Col>
        
            <Col className="gq-card-bg p-5" md="6" lg="6">

                {
                    quizLoaded&&(<><h1>{quiz.label}</h1>
    
                    <p>{quiz.info}</p>
    
                    <button className="gq-button" onClick={(e)=>handleClick(e)}>{content.startQuizLinkLabel}</button></>)
            
                }

                
            </Col>

        </ContainerRow>


    </Container>

    return htmlOutput;
}
export default Intro;