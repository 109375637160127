import React, { createContext, useEffect, useState } from "react";
import { getUser } from "./gqDataPackage/session";

export const UserContext = createContext();
export const LangContext = createContext();

export default function AppContext({ children }) {
	
	const [lang, setLang] = useState("en");

	const [user, setUser] = useState({});

	useEffect(function(){
		setUser(getUser());
	},[]);

	return (
		<LangContext.Provider value={{lang,setLang}}>
			<UserContext.Provider value={{user,setUser}}>
                {children}
            </UserContext.Provider>
		</LangContext.Provider>
	);
}
