/* eslint-disable jsx-a11y/anchor-is-valid */

import { getAccount, getUser } from "../gqDataPackage/session";

import { useEffect, useState } from "react";

import Container, { Col, ContainerRow } from "./bootstrap/Container";

import { requestQuizzesHistoric } from "../gqDataPackage/user";

import HistoricItem from "./HistoricItem";
import { Link, useNavigate } from "react-router-dom";
import AppEnv from "../App.env";
import NoQuizMessage from "./NoQuizMessage";
import Launch from "../launcher/Launch";

function Account() {

	const user = getUser();

	const account = getAccount();

	const navigate = useNavigate();

	const [playHistoric,setPlayHistoric] = useState([]); 

	const [historicLoaded,setHistoricLoaded] = useState(false);

	user.fullName = user.firstName + " " + user.lastName;

	useEffect(()=>{



		async function load(){

			if(!(await AppEnv.isUserLogged())){

				navigate("/login");
				
			}

			const historic = await requestQuizzesHistoric();

			setPlayHistoric(historic);

			setHistoricLoaded(true);

		}

		load();

	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);
	const handleEditInterests = () => {

		navigate("/settings/CategorySelector");
		
	};

	const htmlOutput = (

		<Container className="gq-container px-0 py-5 transparent-cover-2" fluid={true}>

			<ContainerRow className="transparent-cover-2">

				<Col className="px-5 text-center transparent-cover-2 " lg="4">

					<div className="p-3 py-0" style={{ width: "80%", marginLeft: "auto", marginRight: "auto" }}>

						<img style={{ width: "100%", objectFit: "cover", marginTop: "10px" }} src={account.profilePicture} alt={user.fullName} />

					</div>


				</Col>

				<Col className="px-5 transparent-cover-2 text-center" lg="8">

					<div>

						<h1>{user.fullName}</h1>

					</div>

					<button onClick={()=>{navigate("/settings")}} className="gq-button">Edit profile <i className="bi bi-gear-fill"></i></button>

					<hr />

				</Col>

				<Col className="transparent-cover-2 px-5">

					<ContainerRow>

						<Launch dataLoaded={historicLoaded}>


							<Col className="text-end">

								Historic

							</Col>

							<hr/>
							<Col className="text-end">
							
							<button className="gq-button" onClick={handleEditInterests} >
							  
							  Edit Interests <i className="bi bi-pencil-fill"></i>
									  
						  </button>
							</Col>

							{(playHistoric.length<1)&&(<Col>
								<NoQuizMessage/>
							</Col>)}
							<Col>
								{playHistoric.map((play) => (<HistoricItem key={play.playId} quiz={play.quiz} endDateTime={play.endTime} duration={play.duration} score={play.score} />))}

							</Col>
							<hr/>
							<Col className="text-center p-3">
							
								<Link to="/explore">Click here to discover fascinating topics</Link>
							
							</Col>

						</Launch>
					</ContainerRow>

				</Col>

			</ContainerRow>
			
		</Container>
	);

	return htmlOutput;
}
export default Account;
