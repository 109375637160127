import React, { useEffect, useState } from "react";

import { requestCategories, sendUserInterests } from "../gqDataPackage/topics";

import { useNavigate } from "react-router-dom";

import Launch from "../launcher/Launch";

import AppEnv from "../App.env";

import ProcessingRequest from "./ProcessingRequest";

const CategorySelector = () => {

	const navigate = useNavigate();

	const [requestSent,setrequestSent] = useState(false);

	const [selectedCategories, setSelectedCategories] = useState([]);

	const [selectAtLeastOne, setSelectAtLeastOne] = useState(false);

	const [categories, setCategories] = useState([]);

	const [categoryLoaded,setCategoryLoaded] = useState(false);

	useEffect(() => {

		

		async function getCats() {

			let cats = [];

			if (await AppEnv.isUserLogged()) {

				cats = await requestCategories(true);

			} else {

				cats = await requestCategories();

			}

			setCategories(cats.categories);

			if(cats.categories.length>0){

				cats.categories.map((category)=>{if(category.selected){handleCheckboxChange(category.categoryId)}; return 1;});
				
			}
			setCategoryLoaded(true);
		}

		getCats();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);



	const handleCheckboxChange = (category) => {

		setSelectedCategories((prevState) => (prevState.includes(category) ? prevState.filter((item) => item !== category) : [...prevState, category]));
	
	};

	async function handleSubmit() {

		if (selectedCategories.length > 0) {

			setSelectAtLeastOne(false);

			setrequestSent(true);

			const submit = await sendUserInterests(selectedCategories);

			if (submit.execute) {

				navigate("/explore");

			}

			setrequestSent(false);

		} else {

			setSelectAtLeastOne(true);
		
		}
	
	}

	useEffect(
	
		function () {
	
			if (selectedCategories.length > 0) {
	
				setSelectAtLeastOne(false);
	
			} else {
	
				setSelectAtLeastOne(true);
	
			}
	
		},
	
		[selectedCategories]
	
	);

	const htmlOutput= (

		<div className="container py-5">
		
			<h1>Select Your Interests</h1>
		
			<hr />
		
			<p>
				Help us enhance your experience by choosing the categories that intrigue you. Pick the topics you're passionate about or curious to learn more about, and we'll
				feature quizzes and content tailored to your interests!
			</p>
		
			<hr />
		
			<div>
		
				{categories.map((category) => (

					<label key={category.categoryId}>

						<div key={category.name + "" + category.categoryId} className="gq-list-item">

							<input
								type="checkbox"
								id={category.categoryId}
								checked={selectedCategories.includes(category.categoryId)}
								onChange={() => handleCheckboxChange(category.categoryId)}
								className="form-check-input me-2"
							/>

							<label htmlFor={category.categoryId} className="form-check-label">

								{category.name}

							</label>

						</div>

					</label>

				))}

			</div>

			<hr />
			
			{selectAtLeastOne && (

				<>
					<div>Please ensure you select at least one category before proceeding.</div>
					<hr />
				</>

			)}

			{!selectAtLeastOne && (

				<div className="text-center">
					<button onClick={handleSubmit} className="gq-button">
						Done
					</button>
				</div>

			)}
			
		</div>
	);

	if(requestSent){
		return (<ProcessingRequest />);
	}

	return (
		<Launch justSleep={true} dataLoaded={categoryLoaded}>
			{htmlOutput}
		</Launch>
	)
};

export default CategorySelector;
