import { accountNotEmpty } from "./gqDataPackage/session";
import { syncUser } from "./gqDataPackage/user";
import { generateShareLinks } from "./utils";

class AppEnv {
	static async isUserLogged(async=true) {

		if (!accountNotEmpty()) {
			return false;
		}

		if(async){
			return await syncUser();
		}else{
			return accountNotEmpty();
		}

	}

	static logOut(){
		localStorage.clear();
	}

    static baseLink(){
        return "https://gkwiz.yowjerry.com";
    }

	static shareQuiz(quizName,quizLink,score) {
        const message ="I just nailed the "+quizName+" quiz on Gkwiz with a score of "+score+"%!\n\nIf you want to give it a shot and see how you do, here’s the link: "+AppEnv.baseLink()+quizLink+" \n\nHave fun and let me know how you score!";
		const [whatsappLink, facebookLink, xLink] = generateShareLinks(message);
		const links = [
            { link: whatsappLink, icon: "bi bi-whatsapp", label: "Whatsapp" }, 
        { link: facebookLink, icon: "bi bi-facebook", label: "Facebook" }, 
        {link:xLink,icon:"bi bi-twitter-x",label:"Twitter-X"}];
        return links;
	}
}
export default AppEnv;
