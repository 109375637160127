export default function Container({fluid=false,children,id,className}){
    return <div id={id} className={"container"+(fluid?"-fluid":"")+" "+className}>
        {children}
    </div>
}
export function Row({children,className="",id}){
    return <div id={id} className={"row "+className}>
        {children}
    </div>
}
export function Col({children,lg="12",md="12",sm="12",className="",id}){
    return <div id={id} className={"col-sm-"+sm+" col-md-"+md+" col-lg-"+lg+" "+className}>
        {children}
    </div>
}
export function ContainerRow({fluid=false,children,className,id}){
    return <>
        <Container id={id} className={className} fluid={fluid} >
            <Row>
                {children}
            </Row>
        </Container>
    </>
}