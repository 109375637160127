import { Link } from "react-router-dom";

import "../../styles/menu.css";

import { useContext, useEffect, useRef, useState} from "react";

import logo from "../../media/logo.png";

import { UserContext } from "../../AppContext";

import { deepEqual } from "../../utils";

function Menu() {

	const menuRef=useRef(null);

	const toggleButtonRef = useRef(null);

	const [userIsLoggedIn,setUserIsLoggedIn] = useState(false);

	const {user} = useContext(UserContext);

	useEffect(function(){

		setUserIsLoggedIn(!deepEqual(user,{}));

	},[user]);

	function handleClick() {

		if(menuRef.current.classList.contains("show")){

			menuRef.current.classList.remove("show");
		
		}

	}


	const htmlOutput = (

		<nav className="navbar navbar-expand-lg navbar-dark bg-dark sticky-top">

			<div className="container-fluid">

				<a className="navbar-brand" href="/">

					<div style={{width:40,height:40,display:"inline-block",marginRight:5}}>
						<img className="img-fluid" alt="logo" src={logo}/>
					</div>
					<small className="gq-highlight">
						G-KWIZ
					</small>
				</a>

				<button ref={toggleButtonRef} className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarLinks" aria-controls="navbarSupportedCOntent" aria-expanded="false" aria-label="Toggle Navigation">

					<span className="navbar-toggler-icon"></span>
				
				</button>
				
				<div ref={menuRef} className="navbar-collapse collapse" id="navbarLinks" >
					
					<ul className="navbar-nav ms-auto mb-2 me-5">
						
						<li className="nav-item p-2">

							<Link onClick={handleClick} className="nav-link" to="/explore">Explore <i className="bi bi-view-list"></i></Link>

						</li>
						
						{userIsLoggedIn&&<li className="nav-item p-2">

							<Link onClick={handleClick} className="nav-link" to="/profile">Profile <i className="bi bi-person-fill"></i></Link>

						</li>}

						{userIsLoggedIn&&<li className="nav-item p-2">

							<Link onClick={handleClick} className="nav-link" to="/login">Log Out <i className="bi bi-box-arrow-left"></i></Link>

						</li>}
						
						{!userIsLoggedIn&&<li className="nav-item p-2">

							<Link onClick={handleClick} className="nav-link" to="/login">Log In <i className="bi bi-person-fill"></i></Link>

						</li>}
					
					</ul>
				
				</div>
			
			</div>
		
		</nav>
	);

	return htmlOutput;
}

export default Menu;
