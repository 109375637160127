import { useEffect, useRef } from "react";

export default function InputBox({
  autofocus=false,
  children,
  classname,
  icon=false,
  label,
  inputName,
  inputType,
  placeholder,
  eventHandlers=[]
}) {
  let [handleChange] = eventHandlers;
  handleChange===undefined?handleChange=function(){}:console.log();
  const inputRef=useRef(null);
  useEffect(() => {
    if(autofocus){
      inputRef.current.focus();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classname}>
        <label>{label}{" "}{icon&&<i className={icon}></i> }</label>
        <input
          onChange={(e) => handleChange(e)}
          type={inputType}
          placeholder={placeholder}
          name={inputName}
          autoComplete="off"
          ref={inputRef}
        />
        {children}
    </div>
  );
}
