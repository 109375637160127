export default function loadImage(image, setimageIsLoaded = null) {
	if (typeof image === "string") {
		const img = new Image();

		img.src = image;

		img.onload = () => {
			if (setimageIsLoaded != null) {
				setimageIsLoaded(true);
			}
		};
	}
}



export function loadImages(images = [], setimagesLoaded) {

    let curLoad=0;

    function loaded(){
        curLoad+=1;
        if((parseInt(curLoad))===images.length){
            setimagesLoaded(true);
        }
    }

	images.map((image) => {

        const img = new Image();

		img.src = image;

		img.onload = () => {

            loaded();

		};

		return 1;
	});
}
