import * as Dependencies from "./dependencies"; //Importing Necessary Components

import "./styles/gq-assets-style.css";
import AppContext from "./AppContext";
import Scroller from "./components/Scroller";
import CategorySelector from "./components/CategorySelector";
import EditProfile from "./components/EditProfile";
import ProfileUpdateSuccessMessage from "./components/ProfileUpdateSuccessMessage";
import ProfileUpdateFailureMessage from "./components/ProfileUpdateFailureMessage";
import RequestPasswordReset from "./components/RequestPasswordReset";
import ResetPassword from "./components/ResetPassword";

function App() {
	
	return (
		<>

			<AppContext>
				<Dependencies.BrowserRouter>
					<Scroller/>
					<Dependencies.Banner />
					<Dependencies.Routes>
						<Dependencies.Route path="/" element={<Dependencies.Welcome />} />
						<Dependencies.Route path="/explore" element={<Dependencies.Explore />} />
						<Dependencies.Route path="/login" element={<Dependencies.Login />} />
						<Dependencies.Route path="/signup" element={<Dependencies.SignUp />} />
						<Dependencies.Route path="/account" element={<Dependencies.Welcome />} />
						<Dependencies.Route path="/topic/:slug" element={<Dependencies.TopicInfo />} />
						<Dependencies.Route path="/topic/:slug/:quiz" element={<Dependencies.Intro />} />
						<Dependencies.Route path="/topic/:slug/:quiz/play" element={<Dependencies.Play />} />
						<Dependencies.Route path="/topic/:slug/:quiz/leaderboard" element={<Dependencies.Leaderboard />} />
						<Dependencies.Route path="/profile" element={<Dependencies.Account />} />
						<Dependencies.Route path="/categorySelector" element={<CategorySelector />} />
						<Dependencies.Route path="/settings/categorySelector" element={<CategorySelector />} />
						<Dependencies.Route path="/settings" element={<EditProfile />} />
						<Dependencies.Route path="/settings/updateSuccess" element={<ProfileUpdateSuccessMessage />} />
						<Dependencies.Route path="/settings/updateFailure" element={<ProfileUpdateFailureMessage />} />
						<Dependencies.Route path="/request-password-reset" element={<RequestPasswordReset />} />
						<Dependencies.Route path="/reset-password/:token" element={<ResetPassword />} />
					</Dependencies.Routes>
					{/* <Dependencies.Footer /> */}
				</Dependencies.BrowserRouter>
			</AppContext>
		</>
	);
}

export default App;
