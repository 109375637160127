import { Link } from "react-router-dom";

function PasswordRecoverySuccess(){

    const htmlOutput=<>

        <h1>Your Password was recovered successfuly</h1>

        <p>Lorem Ipsum dolor sit amet consecteur adipiscum elit. <Link to="/sign-in">Click Here To login</Link></p>

    </>
    
    return htmlOutput;

}

export default PasswordRecoverySuccess;