import React, { useState, useEffect } from 'react';

const Timer = ({ duration, start, setQuizEnd }) => {
  const [timeLeft, setTimeLeft] = useState(duration);
  const [isActive, setIsActive] = useState(start);

  useEffect(() => {
    let timer;
    if (isActive && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1000);
      }, 1000);
    } else if (timeLeft <= 0) {
      clearInterval(timer);
      setIsActive(false);
      setQuizEnd(true);
    }
    return () => clearInterval(timer);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive,timeLeft]);

  useEffect(() => {
    setIsActive(start);
  }, [start]);



  return (
    <div>
      <h1>{formatTime(timeLeft)}</h1>
    </div>
  );
};

export const formatTime = (time,json=false) => {
  const minutes = Math.floor(time / 60000);
  const seconds = Math.floor((time % 60000) / 1000);
  if(json){
    return {minutes:minutes,seconds:seconds};
  }
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};
export default Timer;
