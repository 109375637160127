import { useNavigate} from "react-router-dom";
import { Col } from "./bootstrap/Container";

function Quiz({ link, label, info, duration }) {
	const navigate = useNavigate();
  function handleClick(e){
	navigate(link+"/play");
  }
	return (
		<Col lg="4" className="p-4 ">
			<div className="gq-card-bg p-5 quizzez">
					<h4>{label}</h4>
					<p>{info}</p>
					<p>{parseInt(duration / 60000)} minutes</p>
          <button className="gq-button" onClick={(e)=>handleClick(e)}>Start Quiz</button>
			</div>
		</Col>
	);
}
export default Quiz;
