import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, ContainerRow } from './bootstrap/Container';
import InputBox from './customBlocks/form/InputBox';
import { testPasswordStrength } from '../utils';
import { resetPassword } from '../gqDataPackage/user';
import ProcessingRequest from './ProcessingRequest';

function ResetPassword() {

  const [password, setPassword] = useState(null);

  const [passwordConfirm, setPasswordConfirm] = useState('');
  
	const [passwordValidationMessage,setpasswordValidationMessage] = useState("");

	const [passwordValid,setPasswordValid] = useState(false);
	
	const [passwordConfirmValidationMessage,setPasswordConfirmValidationMessage] = useState("");
	
	const [passwordConfirmed,setPasswordConfirmed] = useState(false);

  const [passwordResetSuccess,setPasswordResetSuccess] = useState(false);

  const [requestSent,setrequestSent] = useState(false);

  const {token} = useParams();

  const navigate = useNavigate();

  const handleSubmit = async (e) => {

    e.preventDefault();

    if(passwordConfirmed && passwordValid){

      setrequestSent(true);

      const apiResponse=await resetPassword({password:password,token:token});

      setrequestSent(false);

      if(apiResponse.status){

        setPasswordResetSuccess(true);

      }

    }

  };

  function testPassword(e){

    
    const {message,result}=testPasswordStrength(e.target.value);
    
		if(!result){
      
      setPasswordValid(result);
      
			setpasswordValidationMessage(message);
      
		}else{
      
      setPassword(e.target.value);

			setPasswordValid(result);

		}

		if(e.target.value===passwordConfirm){

			setPasswordConfirmed(true);

		}else{

			setPasswordConfirmed(false);

		}
	}
	function testConfirmed(e){

		setPasswordConfirmed(password===e.target.value);

		setPasswordConfirm(e.target.value);

		if(!(password===e.target.value)){

			setPasswordConfirmValidationMessage("The confirmation doesnt match the password you've created");

			setPasswordConfirmed(false);

		}else{

			setPasswordConfirmed(true);
		}

	}

  const successMessage = (
		
		<ContainerRow className="p-4" fluid={true}>
		
			<Col md="6" lg="6" className="p-4">
		
				<h1>Request Success!!</h1>
		
				<p>You've successfuly reset your password.</p>
		
				<button className="gq-button" onClick={()=>{navigate("/login")}}>Click here to log in</button>
		
			</Col>
		
		</ContainerRow>
	
	);

  const htmlOutput=(

    <ContainerRow className='p-4'>

      <form onSubmit={handleSubmit}>

        <Col md='6' lg='6' className='p-4'>
          
          <h1>Password Reset</h1>
          
          <p>
            Please enter your new password to reset your account.   
          </p>
          <hr/>

          <InputBox
            label="Password"
            inputName="password"
            inputType="password"
            placeholder="Create a new password"
            classname="gq-input"
            eventHandlers={[function handleChange(e){testPassword(e)}]}
            autofocus={true}
            icon="bi bi-lock-fill"
          />

          {!passwordValid&&<div className="text-danger px-4"><small>{passwordValidationMessage}</small></div>}
        
          <InputBox
            label="Confirm password"
            inputName="password-confirm"
            inputType="password"
            placeholder="Confirm your new password"
            classname="gq-input"
            eventHandlers={[function handleChange(e){testConfirmed(e)}]}
            icon="bi bi-lock-fill"
          />
          
          {!passwordConfirmed&&<div className="text-danger px-4"><small>{passwordConfirmValidationMessage}</small></div>}
      
        </Col>
        
        <Col lg='6' md='6' className='text-end'>
          <button className='gq-button' type="submit">Set New Password</button>
        </Col>
      </form>
    
    </ContainerRow>
  );

  if(requestSent){
    return <ProcessingRequest />
  }else if(passwordResetSuccess){
    return successMessage;
  }else{
    return htmlOutput;
  }
 
}

export default ResetPassword;
