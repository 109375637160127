import { useState, useEffect } from "react";

const MiniLoader = ({ height = 50, width = 50, text = "GKWIZ..." }) => {
	const classes = ["text-success", "text-danger", "text-warning", "text-info"];
	const [currentClass, setCurrentClass] = useState(0);

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentClass((prevClass) => (prevClass + 1) % classes.length);
		}, 1000);

		return () => clearInterval(interval);
	}, [classes.length]);

	return (
		<>
			<div className={`spinner-grow ${classes[currentClass]}`} style={{ width: width, height: height }} role="status">
				<span className="visually-hidden">Loading...</span>
			</div>
			<div style={{ fontSize: 20 }} className={"text-center " + classes[currentClass]}>
				{text}
			</div>
		</>
	);
};

export default MiniLoader;
