import Container, { Col, ContainerRow } from "./bootstrap/Container";

function UserCard({user}){

    const htmlOutput=<Container className="p-2">
        <ContainerRow className="transparent-cover-2 p-3">
            <Col>
    <h4>
        
        #{user.rank}{" "}{user.fullname}
        </h4>
            </Col>
            <Col>
            
    Score :{user.score}/100
            </Col>
            <Col>
            
    Elapsed time : {user.elapsedTime} sec
            </Col>
        </ContainerRow>



    </Container>

    return htmlOutput;
    
}
export default UserCard;