export default function getResultText(lang){
    const text={
        en:{
            title:"Your Results",
            yourScore:function(score,quizname){
                return "You scored "+Math.floor(score)+"% on "+quizname+"!!";
            },
            goBack:"Explore",
        }
    }
    return text[lang];
}