import AnswerElement from "./AnswerElement";

function Answer({question,selected}){

    const options=question.options;

    const htmlOutput=  <>
    
    <h3>{question.label}</h3>

    <div>
        Answer : <em className="gq-highlight">{question.explanation}</em>
    </div>
    
    <ul>
        {options.map((option)=>(<AnswerElement isCorrect={option.isCorrect} isSelected={selected!==undefined?(parseInt(selected.optionId)===parseInt(option.optionId)):false} key={option.optionId} label={option.label} />))}
    </ul>
    <hr/>
    

    </>

    return htmlOutput;

}
export default Answer;