import { useParams } from "react-router-dom";

import Quiz from "./Quiz";

import { createSlug, getIdFromSLug, getTopicLinkBase } from "../utils";

import { Col} from "./bootstrap/Container";

import GqContainerRow, { GqContainerRowWarp } from "./template/GqContainer";

import { getTopic } from "../gqDataPackage/topics";

import { useEffect, useState } from "react";

import Launch from "../launcher/Launch";

function TopicInfo() {

  const params=useParams();

  const topicId=getIdFromSLug(params.slug);

  let [topic,setTopic]=useState({});

  let [quizzes,setQuizzes]=useState(null);

  const [topicLoaded,setTopicLoaded]=useState(false);

  useEffect(function(){

    (async ()=>{
        
      const topic=await getTopic(topicId);

      setTopic(topic.topic);

      setQuizzes(topic.quizzes);

      setTopicLoaded(true);
  
    })()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  
  const htmlOutput=<>

    <GqContainerRowWarp>

      
    <Launch dataLoaded={topicLoaded} >

      <Col sm="12" md="2" lg="2" >

          <img className="gq-img-fluid ms-auto me-auto" src={topic.coverPicture} alt={topic.name} />

      </Col>

      <Col className="text-center" md="10" lg="10">
        
          <h1>{topic.name}</h1>

          <p>{topic.about}</p>
          
          {/* <p><Link to={getTopicLinkBase(topic.topicId,topic.name)+"/leaderBoard"}>LeaderBoard</Link></p> */}

      </Col>
      
    </Launch>
    </GqContainerRowWarp>
    <hr className="gq-highlight"/>
    <GqContainerRow>
        <Col className="text-center">

<h6 className="gq-highlight">Quizzes about {topic.name}</h6>
        </Col>
        <Launch dataLoaded={topicLoaded} >
          {
              (quizzes!==null)&&quizzes.map((quiz)=><Quiz key={quiz.quizId} label={quiz.label} link={getTopicLinkBase(topic.topicId,topic.name)+"/"+createSlug(quiz.quizId,quiz.label)} duration={quiz.duration} info={quiz.info} />)
          }
        </Launch>
    </GqContainerRow>
      

  </>

  return htmlOutput;

}
export default TopicInfo;
