import "../styles/shareComponent.css";

const ShareComponent = ({ title = 'Share With', links = [] }) => {
  return (
    <div className="share-container">
      <h1>{title}</h1>
      <div className="share-icons">
        {links.map((link, index) => (
          <a key={index} href={link.link} target="_blank" rel="noopener noreferrer" className="share-icon" title={link.label}>
            <i className={`bi ${link.icon}`}></i>
            <span className="icon-label">{link.label}</span>
          </a>
        ))}
      </div>
    </div>
  );
};

export default ShareComponent;
