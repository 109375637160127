export default function getSignIntext(lang){
    const text={
        en:{
            title:"Log In",
            pageMessageParagraph:"Log in to track your progress, take new Quiz, and see how you rank. Your journey to mastering knowledge starts here.",
            emailNotFound:"We couldn't find any account for your e-mail",
            newToWebsite:"New to G-Kwiz? Create an account",
            enterValidEmail:"Please Enter a valid e-mail address",
            emailInputPlaceholder:"Provide Your E-mail",
            emailInputLabel:"E-mail",
            passwordErrorMessage:"Password Incorrect!",
            passwordForgottenMessage:"You forgot your password ? Click Here For Recovery",
            loginButtonLabel:"Log In",
            enterPassword:"Enter Your Password"
        },
    }
    return text[lang];
}