import Container, { ContainerRow } from "../bootstrap/Container";

export default function GqContainerRow({id, children }) {
	return (
		<>
			<Container id={id} fluid={true} className="gq-container transparent-cover">
				<ContainerRow fluid={true} className="gq-page-content transparent-cover">
					{children}
				</ContainerRow>
			</Container>
		</>
	);
}

export function GqContainerRowWarp({id, children,className="" }) {
	return (
		<Container id={id} className="gq-container-warp transparent-cover p-0" fluid="true">
			<ContainerRow className={"text-center p-5 py-0 transparent-cover text-light "+className} fluid={true}>
				{children}
			</ContainerRow>
		</Container>
	);
}
