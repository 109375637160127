import { useNavigate } from "react-router-dom"; 

import getWelcomeText from "./textContent/welcome.text";

import Container, { Col, ContainerRow } from "./bootstrap/Container";

import picture from "../media/welcome-picture.jpg";

import Launch from "../launcher/Launch";

import { useEffect } from "react";

import AppEnv from "../App.env";

const Welcome=()=> {

	const content = getWelcomeText("en");

	const navigate = useNavigate();

	const welcomeImg=<img src={picture} className="gq-img-fluid" alt="welcome"/>

	useEffect(()=>{

		const checkLog=async ()=>{
			
			if(await AppEnv.isUserLogged()){

				navigate("/explore");
	
			}

		}

		checkLog();
		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);

	function handleClick(){

		navigate("/login");

	}

	const html = (<>

		<Container fluid={true}  className="gq-container transparent-cover">

			<ContainerRow fluid={true} className="gq-page-content">

			<Launch image={picture}>

				<Col lg="4" className="p-5 pt-2 gq-card-bg">

					{welcomeImg}

				</Col>

				<Col lg="8" className="p-5 pt-2 gq-card-bg" >

					<h1>{content.title}</h1>

					<p>{content.messageParagraph}</p>

					<button className="gq-button" onClick={handleClick}>{content.getstarted}<i className="bi bi-arrow-right-short"></i></button>

				</Col>

			</Launch>

			</ContainerRow>

		</Container>
	</>
	);

	return html;
}

export default Welcome;
