import Container, { Col, ContainerRow } from "./bootstrap/Container";

import { formatTime } from "./Timer";

import { createSlug, getHourAndMinutesFromTimestamp, getTopicLinkBase } from "../utils";

import { useNavigate } from "react-router-dom";
import AppEnv from "../App.env";
import Modal from "./customBlocks/Modal";
import { useState } from "react";
import ShareComponent from "./ShareComponent";

function HistoricItem({ endDateTime, duration, score, quiz }) {

	const navigate= useNavigate();

	const [shareModalOpen,setShareModalOpen] = useState(false);

	const htmlOutput = (

		<Container >

			<ContainerRow  className="py-5">

				<Col className="px-3">

					<p>

						{new Date(endDateTime).toDateString()}{" "}{getHourAndMinutesFromTimestamp(endDateTime)}

					</p>

				</Col>

				<Col sm="12" md="6" lg="6">

					<img className="gq-img-fluid" alt={quiz.label} src={quiz.coverPicture} />

				</Col>

				<Col sm="12" md="6" lg="6">

					<h4>{quiz.label} </h4>

					<p>{quiz.info}</p>

                    <hr/>

					<p>

						Elapsed Time <span className="gq-highlight">{formatTime(duration)}</span>{" "}
					
                    </p>
					
                    <p>
						
                        Score <span className="gq-highlight">{score}%</span>
					
                    </p>
                    
                 
						<div className="row text-center" style={{fontSize:"small"}}>
							<div className="col-6">
		                        <button onClick={()=>{navigate(getTopicLinkBase(quiz.topicId,quiz.topic.name)+"/"+createSlug(quiz.quizId,quiz.label))}} className="gq-button"><i className="bi bi-arrow-clockwise"></i> Retry</button>				
							</div>
							<div className="col-6">				
								<button onClick={()=>{setShareModalOpen(true)}} className="gq-button ms-3"><i className="bi bi-share-fill"></i> Share</button>
							</div>
						</div>
			

					<Modal isOpen={shareModalOpen} onClose={()=>{setShareModalOpen(false)}}>
		
						<ShareComponent links={AppEnv.shareQuiz(quiz.label,getTopicLinkBase(quiz.topicId,quiz.topic.name)+"/"+createSlug(quiz.quizId,quiz.label),score)} />
		
					</Modal>

				</Col>

			</ContainerRow>

		</Container>

	);
	return htmlOutput;
}

export default HistoricItem;
