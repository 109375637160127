import { useState, useEffect } from 'react';

const Loader = ({height=100,width=100,text="GKWIZ..."}) => { 
    const classes = ['text-success', 'text-danger', 'text-warning', 'text-info'];
    const [currentClass, setCurrentClass] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentClass((prevClass) => (prevClass + 1) % classes.length);
        }, 1000);

        return () => clearInterval(interval);
    }, [classes.length]);

    return (
        <div className="container d-flex align-items-center" style={{height:"50vh"}}>
            <div className="ms-auto me-auto">
                <div className={`spinner-grow ${classes[currentClass]}`} style={{width:width,height:height}} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <div style={{fontSize:20}} className={"text-center "+classes[currentClass]}>
                    {text}
                </div>
            </div>
        </div>
    );
};

export default Loader;
