import { Link, useParams } from "react-router-dom";

import UserCard from "./UserCard";

import { leaderboard } from "../Data/leaderBoard";

import getLeaderBoardText from "./textContent/leaderboard.text";

import { LangContext } from "../AppContext";

import { useContext } from "react";

import Container, { Col, ContainerRow } from "./bootstrap/Container";

function Leaderboard() {
  

  const board = leaderboard;

  const content=getLeaderBoardText(useContext(LangContext));

  const params=useParams();

  const htmlOutput=<Container fluid={true} className="gq-container p-0">

    <ContainerRow fluid={true} className="gq-page-content">
      <Col className="text-center">
        <h1>{board.quiz.name}</h1>
        <h2>{content.title}</h2>
      </Col>
      <hr/>
      <Col>

        <p><Link to={"/quiz/"+params.slug}>{content.goBack}</Link></p>

        {board.users.map((user) => (<UserCard key={user.id} user={user} />))}

      </Col>

    </ContainerRow>
  </Container>

  return htmlOutput;
}
export default Leaderboard;
