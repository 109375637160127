import InputBox from "./customBlocks/form/InputBox";

import GqContainerRow from "./template/GqContainer";

import { Col } from "./bootstrap/Container";

import { useContext, useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";

import { isValidEmail, testPasswordStrength } from "../utils";

import { mailFound, register } from "../gqDataPackage/user";

import { saveUser, updateAccount } from "../gqDataPackage/session";

import { UserContext } from "../AppContext";

import AppEnv from "../App.env";

import MiniLoader from "./template/MiniLoader";

function SignUp() {

	
	const [mailValidationMessage, setMailValidationMessage] = useState("");

	const [mailValid, setMailValid] = useState(false);
	
	const [firstNameValidationMessage,setFirstNameValidationMessage] = useState("");

	const [firstNameValid,setFirstNameValid] = useState(false);
	
	const [lastNameValidationMessage,setlastNameValidationMessage] = useState("");

	const [lastNameValid,setLastNameValid] = useState(false);

	const [passwordValidationMessage,setpasswordValidationMessage] = useState("");

	const [passwordValid,setPasswordValid] = useState(false);
	
	const [passwordConfirmValidationMessage,setPasswordConfirmValidationMessage] = useState("");
	
	const [passwordConfirmed,setPasswordConfirmed] = useState(false);
	
	const [email,setEmail] = useState("");
	
	const [firstName,setFirstName] = useState("");
	
	const [lastName,setLastName] = useState("");
	
	const [password,setPassword] = useState("");
	
	const [passwordConfirmation,setPasswordConfirmation] = useState("");
	
	const [requestSent,setrequestSent] = useState(false);
	// eslint-disable-next-line no-unused-vars
    const {user,setUser} = useContext(UserContext);


	const navigate = useNavigate();

	useEffect(function(){
		AppEnv.logOut();
		setUser({});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	async function handleSubmit(e) {

		e.preventDefault();

		setrequestSent(true);

		if(await mailFound(email)){

			setMailValid(false);

			setMailValidationMessage(<>This e-mail is already in our system, <Link to={"/login"}>Click here to login</Link></>);

			setrequestSent(false);

			return;

		}

		if(mailValid && firstNameValid && lastNameValid && passwordValid){

			const loguser= await register({email:email,firstName:firstName,lastName:lastName,password:password});	

			updateAccount(loguser.login_info.account);

            saveUser(loguser.login_info.user);

			setUser(loguser.login_info.user);

            navigate("/categorySelector");

		}

	}

	function testMail(e){

		setEmail(e.target.value);

		setMailValidationMessage(isValidEmail(e.target.value) ? "" :"Please Enter a valid E-mail Address");

		setMailValid(isValidEmail(e.target.value));

	}

	function testFirstName(e){

		const value = e.target.value.toString().replace(/\s/g, '');

		setFirstName(e.target.value.trim().split(/[\s,\t,\n]+/).join(' '));

		const firstName=value;

		if(firstName.length<3){

			setFirstNameValid(false);

			setFirstNameValidationMessage("Your first name should have more than two (2) characters");

		}else{

			setFirstNameValid(true);

		}

	}

	function testLastName(e){

		const value = e.target.value.toString().replace(/\s/g, '');

		setLastName(e.target.value.trim().split(/[\s,\t,\n]+/).join(' '));

		const lastName=value;

		if(lastName.length<3){

			setLastNameValid(false);

			setlastNameValidationMessage("Your last name should have more than two (2) characters");

		}else{

			setLastNameValid(true);

		}

	}

	function testPassword(e){

		setPassword(e.target.value);

		const {message,result}=testPasswordStrength(e.target.value);

		if(!result){

			setPasswordValid(result);

			setpasswordValidationMessage(message);

		}else{

			setPasswordValid(result);

		}

		if(e.target.value===passwordConfirmation){

			setPasswordConfirmed(true);

		}else{

			setPasswordConfirmed(false);

		}
	}
	function testConfirmed(e){

		setPasswordConfirmed(password===e.target.value);

		setPasswordConfirmation(e.target.value);

		if(!(password===e.target.value)){

			setPasswordConfirmValidationMessage("The confirmation doesnt match the password you've created");

			setPasswordConfirmed(false);

		}else{

			setPasswordConfirmed(true);
		}

	}

	const htmlOutput = (

		<GqContainerRow>

			<Col className="gq-card-bg p-5 pt-1 offset-md-3" md="6" lg="6">

				<h1 >Sign Up <i className="bi bi-file-text-fill"></i></h1>

				<p>Join G-Kwiz to begin your journey of exploring and testing your knowledge across various fields. Sign up now to get started!</p>

				<hr/>

				<form onSubmit={handleSubmit}>

					<InputBox autofocus={true} eventHandlers={[function handleChange(e){testMail(e);}]} icon="bi bi-envelope-at-fill" classname="gq-input input-2" label="E-mail" inputName="email" placeholder="Provide your e-mail" inputType="email" />
					
					{!mailValid&&<div className="text-danger px-4"><small>{mailValidationMessage}</small></div>}
					
					<InputBox eventHandlers={[function handleChange(e){testFirstName(e);}]} icon="bi bi-person-fill" classname="gq-input input-2" label="First Name" inputName="firstName" placeholder="Provide your first name" inputType="text" />

					{!firstNameValid&&<div className="text-danger px-4"><small>{firstNameValidationMessage}</small></div>}

					<InputBox eventHandlers={[function handleChange(e){testLastName(e);}]} icon="bi bi-person-fill" classname="gq-input input-2" label="Last Name" inputName="lastName" placeholder="Provide your last name" inputType="text" />

					{!lastNameValid&&<div className="text-danger px-4"><small>{lastNameValidationMessage}</small></div>}

					<InputBox eventHandlers={[function handleChange(e){testPassword(e);}]} icon="bi bi-lock-fill" classname="gq-input input-2" label="Password" inputName="password" placeholder="Create a password" inputType="password" />

					{!passwordValid&&<div className="text-danger px-4"><small>{passwordValidationMessage}</small></div>}

					<InputBox eventHandlers={[function handleChange(e){testConfirmed(e);}]} icon="bi bi-lock-fill" classname="gq-input input-2 " label="Confirmation" inputName="passwordConfirme" placeholder="Confirm the password" inputType="Password" />

					{!passwordConfirmed&&<div className="text-danger px-4"><small>{passwordConfirmValidationMessage}</small></div>}

					<div className="p-3 text-center">
						{
							requestSent?<MiniLoader text=" " height={10} width={10}/>:<button className="gq-button" type="submit">Sign Up</button>
						}

					</div>

				</form>

				<hr/>

				<p className="text-center">
					{

						(!requestSent)&&<button onClick={()=>{navigate("/login")}} className="gq-button">A have an account? Log in</button>

					}

				</p>

			</Col>

		</GqContainerRow>

  );

  return htmlOutput;

}
export default SignUp;
