import { apiRequest } from "../utils";
import { saveUser, sessionPostData, updateAccount, updateSession } from "./session";
import { baseUrl } from "./config";


const load = baseUrl + "?load=";

const action =baseUrl + "?action=";

export async function login(user) {
	const $post = new FormData();
	$post.append("email", user.email);
	$post.append("password", user.password);
	const request = await apiRequest(baseUrl + "?action=login", $post);
	return request[0];
}

export async function register(data) {
	const user = new FormData();
	user.append("user", JSON.stringify(data));
	const request = await apiRequest(baseUrl + "?action=signUp", user);
	return request[0];
}

export async function updateUserInfo(user) {
	const body = sessionPostData();
	body.append("firstName", user.firstName);
	body.append("lastName", user.lastName);
	if (user.profilePicture != null) {
		body.append("profilePicture", user.profilePicture);
	}
	const request = await apiRequest(baseUrl + "?action=editProfile", body);
	if (request[0].execute) {
		updateSession(request[0].session.account);
		syncUser();
	}
	return request[0];
}

export async function syncUser() {
	const body = sessionPostData(); 
	const request = await apiRequest(baseUrl + "?action=syncUser", body);
	if (request[0].execute) {
		updateAccount(request[0].session.account);
		saveUser(request[0].session.user);
	}
	return request[0].execute;
}

export async function mailFound(email) {
	const request = await apiRequest(baseUrl + "?load=find_mail&email=" + email);
	return request[0].email_found;
}

export async function requestQuizzesHistoric() {
	const url = load + "playHisotric";
	const session = sessionPostData();
	const response = await apiRequest(url, session);
	if (response[0].execute) {
		updateSession(response[0].session.account);
	}
	return response[0].playHistoric;
}

export async function requestPasswordReset(email){
	const body = new FormData();
	body.append("email",email);
	const response=await apiRequest(action+"requestPasswordReset",body);
	return response[0];
}
export async function resetPassword(datas){
	const body = new FormData();
	body.append("new_password",datas.password);
	body.append("token",datas.token);
	const response = await apiRequest(action+"resetPassword",body);
	return response[0];
}