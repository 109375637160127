import React, { useEffect, useState } from "react";
import InputBox from "./customBlocks/form/InputBox";
import Container, { Col, ContainerRow } from "./bootstrap/Container";
import { getProfilePicture, getUser } from "../gqDataPackage/session";
import { useNavigate } from "react-router-dom";
import { updateUserInfo } from "../gqDataPackage/user";
import AppEnv from "../App.env";
import Launch from "../launcher/Launch";
import ProcessingRequest from "./ProcessingRequest";

const EditProfile = () => {
	const [requestSent,setrequestSent] = useState(false);

	const [firstName, setFirstName] = useState("");

	const [lastName, setLastName] = useState("");

	const [profilePicture, setProfilePicture] = useState(null);

	const [profileChanged, setProfileChanged] = useState(false);

	const [currentProfile, setCurrentProfile] = useState(null);

	const [profilePictureFile, setProfilePictureFile] = useState(null);

	const [pageLoad,setPageLoad] = useState(false);

	const navigate = useNavigate();

	const [firstNameValidationMessage,setFirstNameValidationMessage] = useState("");

	const [firstNameValid,setFirstNameValid] = useState(false);

	const [lastNameValidationMessage,setlastNameValidationMessage] = useState("");

	const [lastNameValid,setLastNameValid] = useState(false);

	useEffect(() => {

		async function checkLog(){

			if(!(await AppEnv.isUserLogged())){

				navigate("/login");
				
			}

			const p = await getProfilePicture();

			const user = getUser();
	
			setProfilePicture(p);
	
			setCurrentProfile(p);

			setFirstName(user.firstName);

			setLastName(user.lastName);
	
			setPageLoad(true);

		}

		checkLog();
		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleFileChange = (e) => {

		setProfilePicture(URL.createObjectURL(e.target.files[0]));

		setProfilePictureFile(e.target.files[0]);

		setProfileChanged(true);

	};

	const handleSave = async () => {

		setrequestSent(true);

		const updated=await updateUserInfo({ firstName: firstName, lastName: lastName, profilePicture: profileChanged ? profilePictureFile : null });

		if(updated.execute){

			navigate("/settings/updateSuccess");

		}else{

			navigate("/settings/updateFailure");
			
		}
		setrequestSent(false);

	};

	function revertProfilePicture() {

		setProfileChanged(false);

		setProfilePicture(currentProfile);

	}

	function testFirstName(e){

		const value = e.target.value.toString().replace(/\s/g, '');

		const firstName=value;
		
		if(firstName.length<3){
			
			setFirstNameValid(false);
			
			setFirstNameValidationMessage("Your first name should have more than two (2) characters");
			
		}else{

			setFirstName(e.target.value.trim().split(/[\s,\t,\n]+/).join(' '));
			
			setFirstNameValid(true);

		}

	}

	function testLastName(e){

		const value = e.target.value.toString().replace(/\s/g, '');

		
		const lastName=value;
		
		if(lastName.length<3){
			
			setLastNameValid(false);
			
			setlastNameValidationMessage("Your last name should have more than two (2) characters");
			
		}else{
			
			setLastName(e.target.value.trim().split(/[\s,\t,\n]+/).join(' '));

			setLastNameValid(true);

		}

	}

	const htmlOutput= (
		<Container className="transparent-cover p-4" fluid={true}>

			<ContainerRow className="transparent-cover p-2">

				<Col>

					<button
						onClick={() => {
							navigate("/profile");
						}}
						className="gq-button"
					>

						<i className="bi bi-arrow-left"></i>

					</button>

				</Col>

				<Col>

					<div style={{ padding: "20px", maxWidth: "400px", margin: "0 auto" }}>

						<h2>Edit Profile</h2>

						<div className="text-center">

							<input hidden type="file" id="fileInput" accept="image/*" onChange={handleFileChange} />

							{profilePicture && (
								<div className="p-5">
									<img src={profilePicture} alt="Profile" style={{width:"100%", objectFit: "cover", marginTop: "10px" }} />
								</div>
							)}

							{profileChanged && (
								<div className="py-3 text-end">
									<button onClick={revertProfilePicture} className="gq-button">
										<i className="bi bi-arrow-counterclockwise"></i>
									</button>
								</div>
							)}

							{(!profileChanged)&&<button className="gq-button" onClick={() => document.getElementById("fileInput").click()}>
								Upload New Profile Picture
							</button>
}
						</div>

						<InputBox
							classname="gq-input"
							placeholder={"Change Your First Name"}
							label={"First Name (" + firstName + ")"}
							inputType="text"
							eventHandlers={[
								function handleChange(e) {
									testFirstName(e)
								},
							]}
						/>
						{!firstNameValid&&<div className="text-danger px-4"><small>{firstNameValidationMessage}</small></div>}

						<InputBox
							classname="gq-input"
							placeholder={"Change Your Last Name"}
							label={"Last Name (" + lastName + ")"}
							inputType="text"
							eventHandlers={[
								function handleChange(e) {
									testLastName(e);
								},
							]}
						/>
						{!lastNameValid&&<div className="text-danger px-4"><small>{lastNameValidationMessage}</small></div>}

					</div>

				</Col>

				<Col className="text-center my-2">

					<button className="gq-button" onClick={handleSave}>
						Save Changes
					</button>

				</Col>

			</ContainerRow>

		</Container>

	);

	if(requestSent){
		return (<ProcessingRequest />);
	}

	return <Launch dataLoaded={pageLoad} justSleep={true}>
		{htmlOutput}
	</Launch>
	
};

export default EditProfile;
