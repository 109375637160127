function AnswerElement({label,isCorrect,isSelected}){

    let indicator= (isCorrect?"✔":isSelected&&"✘");
    let style= ((isCorrect&&isSelected)?"bg-success":"");
    if(!isCorrect && isSelected){
        style="bg-danger";
    }

    const htmlOutput=<><li className={style.toString()+" p-2 my-4"}>{label}{indicator}</li></>

    return htmlOutput;

}

export default AnswerElement;