export function getTopicLinkBase(id, name) {
	return "/topic/" + createSlug(id, name);
}

export function createSlug(id, label) {
	if (label !== undefined) return label.toLowerCase().replace(/\s+/g, "-") + "-" + id;
	else return "undefinded";
}
function getLastValueAfterDash(str) {
	const parts = str.split("-");
	return parts[parts.length - 1];
}
export function getIdFromSLug(slug) {
	return getLastValueAfterDash(slug);
}

export function isValidEmail(email) {
	// Define a regular expression for validating email addresses
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	// Test the email against the regular expression
	return emailRegex.test(email);
}

export function isIterable(obj) {
	// checks for null and undefined
	if (obj == null) {
		return false;
	}
	return typeof obj[Symbol.iterator] === "function";
}

export function toMinutes(milliseconds) {
	// Convert milliseconds to seconds
	let seconds = Math.floor(milliseconds / 1000);

	// Calculate minutes
	let minutes = Math.floor(seconds / 60);
	seconds = seconds % 60; // Remaining seconds

	// Calculate milliseconds (tierce)
	let tierce = milliseconds % 1000;

	return {
		minutes: minutes,
		seconds: seconds,
		tierce: tierce,
	};
}

export function deepEqual(object1, object2) {
	const keys1 = Object.keys(object1);
	const keys2 = Object.keys(object2);

	if (keys1.length !== keys2.length) {
		return false;
	}

	for (const key of keys1) {
		const val1 = object1[key];
		const val2 = object2[key];
		const areObjects = isObject(val1) && isObject(val2);
		if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
			return false;
		}
	}

	return true;
}

function isObject(object) {
	return object != null && typeof object === "object";
}

export function shuffleArray(array) {
	// Create a copy of the original array
	const shuffledArray = array.slice();

	// Shuffle the elements
	for (let i = shuffledArray.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
	}

	return shuffledArray;
}

export async function apiRequest(url, body = null) {
	if (body === null) {
		return await fetch(url)
			.then((x) => x.json())
			.then((y) => y);
	}

	if (body !== null) {
		const request = new Request(url, { method: "POST", body: body });
		return await fetch(request)
			.then((x) => x.json())
			.then((y) => y);
	}
	return {};
}

export function getHourAndMinutesFromTimestamp(timestamp){
	const date=new Date(timestamp);
	const h=parseInt(date.getHours());
	const m=parseInt(date.getMinutes());
	return (h%12)+":"+(m<10?0:"")+(m)+(h>=12?" PM":" AM");
}

export function testPasswordStrength(password) {
    let message = "Your password is strong.";
    let issues = [];

    // Criteria checks
    const lengthRequirement = password.length >= 8;
    const upperCaseRequirement = /[A-Z]/.test(password);
    const lowerCaseRequirement = /[a-z]/.test(password);
    const numberRequirement = /\d/.test(password);
    const specialCharRequirement = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    // Collecting issues
    if (!lengthRequirement) issues.push("at least 8 characters long");
    if (!upperCaseRequirement) issues.push("at least one uppercase letter");
    if (!lowerCaseRequirement) issues.push("at least one lowercase letter");
    if (!numberRequirement) issues.push("at least one number");
    if (!specialCharRequirement) issues.push("at least one special character (e.g., !, @, #, $, etc.)");

    // Creating feedback message
    if (issues.length > 0) {
        message = `Your password should have ${issues.join(', ')}.`;
    }

    return {message:message,result:(lengthRequirement && upperCaseRequirement && lowerCaseRequirement && numberRequirement && specialCharRequirement)};
}

export async function uploadFile(file, url, options = {}, fileField = 'file') {
    if (!file) {
        throw new Error('No file provided');
    }

    // Create a FormData object to hold the file
    const formData = new FormData();
    formData.append(fileField, file);

    try {
        // Send a POST request with the FormData object
        const response = await fetch(url, {
            method: 'POST',
            body: formData,
            ...options // Merge additional options
        });

        // Check if the request was successful
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Return the response object
        return response;
    } catch (error) {
        console.error('Error uploading file:', error);
        throw error;
    }
}

export function generateShareLinks(message) {
    // Encode the message for URL compatibility
    const encodedMessage = encodeURIComponent(message);

    // Construct share links
    const whatsappLink = `https://wa.me/?text=${encodedMessage}`;
    const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${encodedMessage}`;
    const xLink = `https://twitter.com/intent/tweet?text=${encodedMessage}`;

    // Return an array of links
    return [whatsappLink, facebookLink, xLink];
}