import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import Launch from "./launcher/Launch";
import background from "./media/welcome.jpg";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	<Launch images={[background, background, background]} image={background}>
		<App />
	</Launch>
);
