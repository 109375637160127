import { useNavigate } from "react-router-dom";


const ProfileUpdateSuccessMessage = () => {

  const navigate = useNavigate();

  const handleButtonClick = () => {
      navigate("/profile")
  };

  return (
    <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>Update Status</h1>
            <p>Profile updates were executed successfully!</p>
            <button className="gq-button" onClick={handleButtonClick}>Ok</button>
          </div>
        </div>
    </div>
  );
};

export default ProfileUpdateSuccessMessage;
