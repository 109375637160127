import { deepEqual } from "../utils";

export function updateAccount(account) {
	localStorage.setItem("account", JSON.stringify(account));
}
export function getSessionKey() {
	const account = getAccount();
	if (Object.keys(account).length > 0) {
		return account.sessionKey;
	} else {
		return null;
	}
}
export function accountNotEmpty() {
	const account = getAccount();
	return Object.keys(account).length > 0;
}
export function updateSession(session) {
	let account = getAccount();
	if (accountNotEmpty()) {
		account = { ...account, sessionKey: session.sessionKey, sessionExpire: session.sessionExpire};
		updateAccount(account);
	}
}

export function getAccount() {
	const accountString = localStorage.getItem("account");
	const account = JSON.parse(accountString != null ? accountString : "{}");
	return account;
}
export function clearSession() {
	localStorage.clear();
}
export function saveUser($user) {
	localStorage.setItem("user", JSON.stringify($user));
}
export function getUser() {
	let user = localStorage.getItem("user");
	return JSON.parse(user != null ? user : "{}");
}

export function getProfilePicture() {
	const account = getAccount();
	if (Object.keys(account).length > 0) {
		return account.profilePicture;
	} else {
		return null;
	}
}


export function sessionPostData(){
	const sessionKey = getSessionKey();
	const user = getUser();
	const $post = new FormData();
	const returnNull = sessionKey==null || deepEqual(user,{});
	if(returnNull){
		return null;
	}
	$post.append("e", sessionKey);
	$post.append("user", user.email);
	return $post;
}