export default function getExploreText(lang){
    const text={
        en:{
            title:"Explore",
            message:" Choose a Topic and challenge yourself now!",
            searchBarPlaceholder:"Search for a Topic",
            start:"VIEW",
            leaderboardLinkLabel:"Leaderboard",
            time:"Minutes",
            quiz:"Quiz",
            search:"Search",
            browse:"Browse Topics"
        }
    }
    return text[lang];
}